import { Component } from '@angular/core';
import { Shopping } from "./shopping";
import { Location } from '@angular/common';

@Component({
  selector: 'bonus',
  templateUrl: './bonus.html',
  styleUrls : [ './bonus.scss' ]
})

export class Bonus {
  constructor(
    public loc : Location,
    public shopping : Shopping
  ){
    this.shopping.setLocation('bonus');
  }
}
