import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routing } from './app.routing';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { Main } from "./";
import { Shopping, Company, Customer, News } from "./";
import { Bonus, Gift, Leaflet, Mobile } from "./";
import { Ceo, Condition, Historyes, Info } from "./";
import { Faq, Enter, Guest, GuestDetail, GuestWrite, GuestPwd, View, EnterDetail } from "./";
import { Employ, Notice, Detail, Pwd, EmployWrite } from "./";

// import { MyDatePickerModule } from 'mydatepicker';
import { ImageUploadModule } from "angular2-image-upload";
import { APP_BASE_HREF, DatePipe, LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpProvider, UploaderProvider, PagerService } from './_providers';
import { SizePipe, PhonePipe, BytePipe } from './_pipes';


import { ImageViewerModule } from "ngx-image-viewer";

@NgModule({
	declarations: [
		AppComponent,
		Main,
		Shopping, Company, Customer, News,
		Bonus, Gift, Leaflet, Mobile,
		Ceo, Condition, Historyes, Info,
		Faq, Guest, GuestDetail, GuestWrite, GuestPwd, Enter, View, EnterDetail,
		Employ, Notice, Detail, Pwd, EmployWrite,
		PhonePipe, SizePipe, BytePipe
	],
	schemas : [ CUSTOM_ELEMENTS_SCHEMA ],
	imports: [
	BrowserAnimationsModule,
		HttpClientModule,
		BrowserModule,
		ReactiveFormsModule,
		routing,
		BrowserModule,
		// MyDatePickerModule,
		FormsModule,
		ImageUploadModule.forRoot(),
		ImageViewerModule.forRoot()
	],
	providers: [
		HttpProvider, UploaderProvider, DatePipe, PagerService,
    { provide: APP_BASE_HREF, useValue : '/' },
	// 	{
	// 		provide: LocationStrategy,
	// 		useClass: HashLocationStrategy
	//  },
    { provide: LocationStrategy, useClass: PathLocationStrategy },

    // { provide: LocationStrategy, useClass: PathLocationStrategy }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
