import { Component } from '@angular/core';
import { AppComponent } from "../app.component";
import { Router } from '@angular/router';
import { HttpProvider } from '../_providers';


@Component({
  selector: 'shopping',
  templateUrl: './shopping.html',
  styleUrls : [ './shopping.scss' ]
})

export class Shopping {
  path : string;
  store_list : any;
  store : any;
  constructor(
    public app : AppComponent,
    public router : Router,
    public http : HttpProvider
  ){
    this.app.setPath('shopping');
    this.http.get('select_list').then(res=>{
      if(res['result']){
        this.store_list = res['data'];
      }
    })
  }

  setStore(name){
    this.store = name;
  }

  selectStore(name, id){
    this.store = name;
    this.router.navigate([`shopping/leaflet/${name}/${id}`]);
    window.scrollTo(0,0);
  }

  setLocation(menu){
    this.path = menu;
    window.scrollTo(0,0);
  }

  leaflet(){
    this.path = 'leaflet';
    this.router.navigate([`shopping/leaflet/감천점/2`]);
  }


  getStore(){
    return this.store;
  }

  // loadStoreList(){
  //   return new Promise(resolve=>{
  //     this.http.get('http://211.110.1.33:1234/select_list')
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }
}
