import { Component, ViewChild } from "@angular/core";
import { Customer } from "./customer";
import { HttpProvider } from "../_providers";
import { ElementRef } from "@angular/core";

@Component({
  selector: "enter",
  templateUrl: "./enter.html",
  styleUrls: ["./enter.scss"],
})
export class Enter {
  @ViewChild("fileInput")
  fileInput: ElementRef;
  files: any = [];
  agree: boolean = false;
  insertInfo: any = {
    name: "",
    phone: "",
    content: "",
  };

  constructor(public customer: Customer, public http: HttpProvider) {
    window.scrollTo(0, 0);
    this.customer.setLocation("enter");
  }

  onChangeFiles(ev) {
    const {
      target: { files },
    } = ev;

    const filesArray = Array.from(files);

    this.files = [...this.files, ...filesArray];

    this.fileInput.nativeElement.value = "";
  }

  reset() {
    this.files = [];
    this.fileInput.nativeElement.value = "";
  }

  remove(i) {
    this.files = this.files.filter((item, idx) => idx !== i);
  }

  register() {
    if (!this.agree) {
      alert("개인정보 이용란에 동의해주세요");
      return;
    }
    const { name, phone, content } = this.insertInfo;
    if (name === "" || phone === "" || content === "") {
      alert("입력되지 않은 항목이 있습니다");
      return;
    }

    const formData = new FormData();

    for (let key in this.insertInfo) {
      formData.append(key, this.insertInfo[key]);
    }

    if (this.files.length > 0) {
      for (let key in this.files) {
        formData.append("enter", this.files[key]);
      }
    }

    this.http.post("insertEnter", formData).then((res) => {
      if (res["result"]) {
        alert("등록되었습니다.");
        this.insertInfo = {
          name: "",
          phone: "",
          content: "",
        };
        this.reset();
      } else {
        console.log(res["error"]);
        alert("네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.");
      }
    });
  }
}
