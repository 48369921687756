import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'byte'
})
export class BytePipe implements PipeTransform{
  transform(fileSize, args){
    let str;

    if (!fileSize) {
      return '';
    }

    if (fileSize >= 1024 * 1024) {
        fileSize = Math.round(fileSize / (1024 * 1024));
        str = this.commaNum(fileSize) + 'MB';
    } else if (fileSize >= 1024) {
        fileSize = Math.round(fileSize / 1024);
        str = this.commaNum(fileSize) + 'KB';
    } else {
        str = this.commaNum(fileSize) + 'Byte';
    }

    return str;
  }

  commaNum(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
