import { Component } from '@angular/core';
import { AppComponent } from "../app.component";

@Component({
  selector: 'customer',
  templateUrl: './customer.html'
})

export class Customer {
  path : string;
  constructor(
    public app : AppComponent
  ){
    this.app.setPath('customer');
  }

  setLocation(menu){
    this.path = menu;
    window.scrollTo(0,0);
  }
}
