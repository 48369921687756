import { Component } from '@angular/core';
import { News } from "./news";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpProvider } from "../_providers";

@Component({
  selector: 'detail',
  templateUrl: './detail.html',
  styleUrls: ['./detail.scss']
})

export class Detail {
  fileList:any = [];
  id:number;
  type:string;
  bigFile:string = "";
  titles:string;
  file1:string = "";
  file2:string = "";
  file3:string = "";
  detailInfo : any = {
    content : "",
    date : "",
    id : 0,
    name : "",
    store_id : 0,
    title : ""
  };

  constructor(
    public news : News,
    public router : Router,
    public route : ActivatedRoute,
    public http : HttpProvider
  ){
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.type = params['type'];
      if(this.type == "notice"){
        this.titles = "공지사항";
      }else{
        this.titles = "채용공고";
      }
      this.news.setLocation(this.type);

      this.http.post('select_newsdetail', {id:this.id, type:this.type}).then(res=>{
        if(res['result']){
          this.detailInfo = res['data'];
        }
      })

      if(this.type == 'notice'){
        this.http.post('select_files', {id:this.id}).then(res=>{
          if(res['result']){
            this.fileList = res['data'];
          }
        })
      }

    });
  }

  delete(){
    this.router.navigate(['news/pwd', {id:this.detailInfo.id, type:this.type, use:'delete'}]);
  }

  modify(){
    this.router.navigate(['news/pwd', {id:this.detailInfo.id, type:this.type, use:'modify'}]);
  }

  show(imageUrl: string) {
    this.bigFile = `https://woori-mart.co.kr/assets/notice/${imageUrl}`;
  }
  
  reset() {
    this.bigFile = "";
  }

  toList(){
    let url = "news/"+this.type
    this.router.navigate([url]);
  }
}
