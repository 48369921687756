import { Component } from '@angular/core';
import { PagerService, HttpProvider } from "../_providers";
import { Router } from '@angular/router';
import { News } from "./news";

@Component({
  selector: 'notice',
  templateUrl: './notice.html',
  styleUrls: ['./notice.scss']
})

export class Notice {
  pager: any = {};
  pagedItems: any[];
  search_word: string = '';
  length: number;
  private allItems: any[];

  constructor(
    public news : News,
    public http : HttpProvider,
    public router : Router,
    public pagerService : PagerService
  ){
    this.news.setLocation('notice');
    this.loadPage();
  }

  err(msg){
    alert(msg);
  }

  loadPage(){
    this.http.get('select_notice').then(res=>{
      if(res['result']){
        this.pager = {};
        this.allItems = res['data'];
        this.length = this.allItems.length;
        this.setPage(1);
      }
    })
  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.pagerService.getPager(this.allItems.length, page, 10);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  detail(id){
    this.router.navigate([`news/detail/notice/${id}`]);
  }

  search(){
    if(this.search_word == ''){
      this.err('검색어를 입력하세요');
    }else{
      this.http.post('search_notice', {word:this.search_word}).then(res=>{
        if(res['result']){
          this.pager = {};
          this.allItems = res['data'];
          this.setPage(1);
        }
      });
    }
  }



  // loadNotice(){
  //   return new Promise(resolve=>{
  //     this.http.get('http://211.110.1.33:1234/select_notice')
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  // loadSearch(word){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/search_notice', {word:word})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  write(){
    this.router.navigate(['news/pwd', {type:'notice', use:'write'}]);
    window.scrollTo(0,0);
  }
}
