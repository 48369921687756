import { Component, HostListener } from '@angular/core';
import { PagerService, HttpProvider } from '../_providers';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Shopping } from './shopping';
import { environment } from 'src/environments/environment.prod';
import { ImageViewerConfig } from 'ngx-image-viewer';

@Component({
  selector: 'leaflet',
  templateUrl: './leaflet.html',
  styleUrls: ['./leaflet.scss'],
})
export class Leaflet {
  image_api: string = environment.fileUrl;
  pager: any = {};
  pagedItems: any[];
  store: string;
  src: string = '';
  front: string = '';
  back: string = '';
  store_id: number;
  count: number;
  leaflet_list: any;
  view: boolean = false;
  fade: boolean = false;
  page: boolean = true;
  images: string[];
  config: ImageViewerConfig = {
    btnClass: 'default', // The CSS class(es) that will apply to the buttons
    zoomFactor: 0.1, // The amount that the scale will be increased by
    containerBackgroundColor: '#ccc', // The color to use for the background. This can provided in hex, or rgb(a).
    wheelZoom: true, // If true, the mouse wheel can be used to zoom in
    allowFullscreen: false, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
    allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
    btnIcons: {
      // The icon classes that will apply to the buttons. By default, font-awesome is used.
      zoomIn: 'fa fa-plus',
      zoomOut: 'fa fa-minus',
      rotateClockwise: 'fa fa-repeat',
      rotateCounterClockwise: 'fa fa-undo',
      next: 'fa fa-arrow-right',
      prev: 'fa fa-arrow-left',
      fullscreen: 'fa fa-arrows-alt',
    },
    btnShow: {
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: false,
      rotateCounterClockwise: false,
      next: true,
      prev: true,
    },
  };
  temp: any;

  constructor(
    public shopping: Shopping,
    public http: HttpProvider,
    public datePipe: DatePipe,
    public route: ActivatedRoute,
    public pagerService: PagerService
  ) {
    this.shopping.setLocation('leaflet');
    this.route.params.subscribe((params) => {
      this.store_id = params.store;
      this.store = params.name;
      this.shopping.setStore(this.store);
      this.loadInit(this.store_id);
    });
  }

  handleEvent(e) {
    console.log(e);
  }

  err(msg) {
    alert(msg);
    //  swal({
    //    title: '',
    //    text: msg,
    //    confirmButtonText: '확인'
    //  }).catch(()=>{});
  }

  viewLeaflet(img, _end) {
    this.front = `${this.image_api}leaf/front/${img}`;
    this.back = `${this.image_api}leaf/back/${img}`;
    const _today = new Date();
    const today = this.datePipe.transform(_today, 'yyyy-MM-dd');
    const end = this.datePipe.transform(_end, 'yyyy-MM-dd');

    if (today > end) {
      this.err('종료된 이벤트입니다');
    } else {
      this.src = this.front;
      this.view = true;
      this.page = true;

      this.images = [this.front, this.back];
    }
  }

  close() {
    this.fade = true;
    setTimeout(() => {
      this.view = false;
      this.fade = false;
      this.src = '';
      this.front = '';
      this.back = '';
    }, 300);
  }

  flip() {
    if (this.src === this.front) {
      this.src = this.back;
    } else {
      this.src = this.front;
    }
    this.page = !this.page;
  }

  fronts() {
    this.src = this.front;
    this.page = true;
  }

  backs() {
    this.src = this.back;
    this.page = false;
  }

  loadInit(id) {
    this.http.post('select_event', { store_id: id }).then((res) => {
      if (res['result']) {
        try {
          this.leaflet_list = res['data'];
          this.temp = `${this.image_api}leaf/front/${this.leaflet_list[0].img}`;
          this.count = res['data'].length;
          if (this.count > 0) {
            this.setPage(1);
          } else {
            this.pagedItems = [];
            this.pager = 0;
          }
        } catch (error) {
          this.pagedItems = [];
          this.pager = 0;
          this.count = 0;
        }
      }
    });
  }

  // loadLeaflet(id){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/select_event', {store_id:id})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.pagerService.getPager(this.leaflet_list.length, page, 6);
    this.pagedItems = this.leaflet_list.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.view) {
      if (event.keyCode === 39 || event.keyCode === 37) {
        // 방향키
        this.flip();
      } else if (event.keyCode === 27) {
        // esc
        this.close();
      } else if (event.keyCode === 49) {
        // 1
        this.fronts();
      } else if (event.keyCode === 50) {
        // 2
        this.backs();
      }
    }
  }

  failed() {
    this.close();
    this.err('전단이미지가 없습니다');
  }
}
