import { Component } from '@angular/core';
import { Customer } from "./customer";
import { Router } from '@angular/router';
import { PagerService, HttpProvider } from "../_providers";
// import * as _ from 'underscore';

@Component({
  selector: 'guest',
  templateUrl: './guest.html',
  styleUrls: ['./guest.scss']
})

export class Guest {
  pager: any = {};
  pagedItems: any[];
  search_word: string = '';
  allItemsLength: number = 0;
  private allItems: any[];

  constructor(
    public customer : Customer,
    public router : Router,
    public pagerService : PagerService,
    public http : HttpProvider
  ){
    this.customer.setLocation('guest');
    this.loadPage();
  }

  loadPage(){
    this.http.get('select_guest').then(res=>{
      if(res['result']){
        this.pager = {};
		  this.allItems = res['data'];
		  this.allItemsLength = res['data'].length;
        this.setPage(1);
      }
    });
  }

  enter() {
    this.router.navigate(['customer/enter-view']);
    window.scrollTo(0,0);
  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }

    this.pager = this.pagerService.getPager(this.allItems.length, page, 10);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  
  detail(id, group){
    this.router.navigate(['customer/guest-detail', {id:id}]);
    window.scrollTo(0,0);
  }
  
  write(){
    this.router.navigate(['customer/guest-write', {type:'write'}]);
    window.scrollTo(0,0);
  }
  
  search(){
    if(this.search_word == ''){
      this.err('검색어를 입력하세요');
    }else{
      this.http.post('search_guest', {word:this.search_word}).then(res=>{
        if(res['result']){
          this.pager = {};
          this.allItems = res['data'];
			 this.allItemsLength = res['data'].length;
          this.setPage(1);
        }
      });
    }
  }
  
  err(msg){
    alert(msg);
  }

  // loadGuest(){
  //   return new Promise(resolve=>{
  //     this.http.get('http://211.110.1.33:1234/select_guest')
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  // loadSearch(word){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/search_guest', {word:word})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }
}
