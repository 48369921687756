import { Component } from '@angular/core';
import { Company } from "./company";

@Component({
  selector: 'info',
  templateUrl: './info.html'
})

export class Info {
  constructor(
    public company : Company
  ){
    this.company.setLocation('info');
  }
}
