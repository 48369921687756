import { Component } from '@angular/core';
import { Shopping } from './shopping';
import { Location } from '@angular/common';

@Component({
  selector: 'mobile',
  templateUrl: './mobile.html',
  styleUrls: ['./mobile.scss']
})
export class Mobile {
  constructor(public loc: Location, public shopping: Shopping) {
    this.shopping.setLocation('mobile');
  }
};