import { Component } from '@angular/core';
import { Customer } from "./customer";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpProvider } from "../_providers";

@Component({
  selector: 'guest-pwd',
  templateUrl: './guest-pwd.html'
})

export class GuestPwd {
  pwd : any;
  id : number;
  group : number;
  type : string;

  constructor(
    public customer : Customer,
    public http : HttpProvider,
    public router : Router,
    public route : ActivatedRoute
  ){
    window.scrollTo(0,0);
    this.customer.setLocation('guest');
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.type  = params['type'];
      this.group = params['group'];
    });
  }

  err(msg){
    alert(msg);
  }

  success(msg){
    alert(msg);
  }
  


  event(){
    this.http.post('reply_post', {pwd:this.pwd}).then(res=>{
      if(res['result']){
        if(res['result2']){
          this.router.navigate(['customer/guest-view']);
          window.scrollTo(0,0);
        }else{
          this.pwd = "";
          this.err('비밀번호가 일치하지 않습니다');
        }
      }else{
        this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
      }
    })
  }

  // event(){
  //   switch(this.type){
  //     case 'delete' : {
  //       this.http.post('http://211.110.1.33:1234/delete_post', {id:this.id, pwd:this.pwd})
  //       .map(res=>res.json())
  //       .subscribe(res=>{
  //         if(res['result']){
  //           if(res['result2']){
  //             this.success('삭제되었습니다');
  //             this.router.navigate(['customer/guest']);
  //             window.scrollTo(0,0);
  //           }else{
  //             this.err('비밀번호가 일치하지 않습니다');
  //             this.pwd = "";
  //           }
  //         }else{
  //           this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
  //         }
  //       })
  //     }break;
  //
  //     case 'modify' : {
  //       this.http.post('http://211.110.1.33:1234/check_pwd', {id:this.id, pwd:this.pwd})
  //       .map(res=>res.json())
  //       .subscribe(res=>{
  //         if(res['result']){
  //           if(res['result2']){
  //             this.router.navigate(['customer/guest-write', {id:this.id, type:'modify'}]);
  //             window.scrollTo(0,0);
  //           }else{
  //             this.err('비밀번호가 일치하지 않습니다');
  //             this.pwd = "";
  //           }
  //         }else{
  //           this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
  //         }
  //       })
  //     }break;
  //
  //     case 'reply' : {
  //       this.http.post('http://211.110.1.33:1234/reply_post', {id:this.id, pwd:this.pwd})
  //       .map(res=>res.json())
  //       .subscribe(res=>{
  //         if(res['result']){
  //           if(res['result2']){
  //             this.router.navigate(['customer/guest-write', {id:this.id, type:'reply'}]);
  //             window.scrollTo(0,0);
  //           }else{
  //             this.pwd = "";
  //             this.err('비밀번호가 일치하지 않습니다');
  //           }
  //         }else{
  //           this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
  //         }
  //       })
  //     }break;
  //
  //     case 'read' : {
  //       this.http.post('http://211.110.1.33:1234/check_group', {id:this.group, pwd:this.pwd})
  //       .map(res=>res.json())
  //       .subscribe(res=>{
  //         if(res['result']){
  //           if(res['result2']){
  //             this.router.navigate(['customer/guest-detail', {id:this.id}]);
  //             window.scrollTo(0,0);
  //           }else{
  //             this.http.post('http://211.110.1.33:1234/check_admin', {pwd:this.pwd})
  //             .map(res=>res.json())
  //             .subscribe(res=>{
  //               if(res['result']){
  //                 if(res['result2']){
  //                   this.router.navigate(['customer/guest-detail', {id:this.id}]);
  //                   window.scrollTo(0,0);
  //                 }else{
  //                   this.pwd = "";
  //                   this.err('비밀번호가 일치하지 않습니다');
  //                 }
  //               }else{
  //                 this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요1');
  //               }
  //             })
  //           }
  //         }else{
  //           this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요2');
  //         }
  //       })
  //     }
  //   }
  // }
}
