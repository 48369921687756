import { ElementRef, ViewChild, Component } from "@angular/core";
import { News } from "./news";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { IMyDpOptions } from "mydatepicker";
import { UploaderProvider } from "../_providers";
import { HttpProvider } from "../_providers";
import { environment } from "src/environments/environment.prod";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "employ-write",
  templateUrl: "./employ-write.html",
  styleUrls: ["./employ-write.scss"]
})
export class EmployWrite {
  apiUrl = environment.apiUrl;
  form: FormGroup;
  loading = false;
  imageSrc: any;
  file;
  // imageSrc = '/assets/img/app_banner.jpg';
  result; // file upload 수행 이후 서버로부터 수신한 데이터

  @ViewChild("noticeFiles") nf: any;
  @ViewChild("fileInput") fileInput: ElementRef;
  filesForUpload = new Array();
  files = new Array();
  fileList: any = [];
  tmpName: any = [];
  options: Object = {
    url: "/assets/upload.php"
  };

  store_list: any;
  type: any;
  image: any;
  use: any;
  check: boolean = false;
  modi_id: number;
  id: number;
  email: string = "";
  emails: string = "s";
  insertInfo: any = {
    store: 999,
    title: "",
    date: null,
    content: ""
  };
  product: any = [];
  private myDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    dayLabels: {
      su: "일",
      mo: "월",
      tu: "화",
      we: "수",
      th: "목",
      fr: "금",
      sa: "토"
    },
    monthLabels: {
      1: "1월",
      2: "2월",
      3: "3월",
      4: "4월",
      5: "5월",
      6: "6월",
      7: "7월",
      8: "8월",
      9: "9월",
      10: "10월",
      11: "11월",
      12: "12월"
    },
    todayBtnTxt: "오늘날짜",
    componentDisabled: false,
    editableDateField: false
  };

  constructor(
    private uploadProvider: UploaderProvider,
    public el: ElementRef,
    public news: News,
    public router: Router,
    public http: HttpProvider,
    public httpClient: HttpClient,
    private fb: FormBuilder,
    public datePipe: DatePipe,
    public route: ActivatedRoute
  ) {
    window.scrollTo(0, 0);
    this.uploadProvider.setOptions(this.options);
    // this.uploadProvider.getStatusPHP.subscribe((data) => {
    //	 let res = data.response;
    //	 let arr = res.replace('{', '');
    //	 arr = res.replace('}', '');
    //	 arr = res.split(',');
    //	 console.log(arr);
    // });
    this.http.get("store_list").then(res => {
      if (res["result"]) {
        this.store_list = res["data"];
      }
    });

    this.route.params.subscribe(params => {
      this.type = params["type"];
      this.use = params["use"];
      if (params["id"]) {
        this.id = params["id"];
      }
      this.news.setLocation(this.type);
      if (this.use === "modify") {
        this.http
          .post("select_newsdetail", { id: this.id, type: this.type })
          .then(res => {
            if (res["result"]) {
              let data = res["data"];
              if (this.type === "employ") {
                let date = this.datePipe.transform(data.date, "yyyy-MM-dd");
                let y = this.datePipe.transform(data.date, "yyyy");
                let m = this.datePipe.transform(data.date, "M");
                let d = this.datePipe.transform(data.date, "d");
                if (date === "2099-12-31") {
                  this.check = true;
                  this.myDatePickerOptions = {
                    dateFormat: "yyyy-mm-dd",
                    dayLabels: {
                      su: "일",
                      mo: "월",
                      tu: "화",
                      we: "수",
                      th: "목",
                      fr: "금",
                      sa: "토"
                    },
                    monthLabels: {
                      1: "1월",
                      2: "2월",
                      3: "3월",
                      4: "4월",
                      5: "5월",
                      6: "6월",
                      7: "7월",
                      8: "8월",
                      9: "9월",
                      10: "10월",
                      11: "11월",
                      12: "12월"
                    },
                    todayBtnTxt: "오늘날짜",
                    componentDisabled: true,
                    editableDateField: false
                  };
                  date = "";
                }

                this.insertInfo = {
                  store: data.store_id,
                  title: data.title,
                  content: data.content
                };

                this.insertInfo.date = {
                  date: {
                    year: y,
                    month: m,
                    day: d
                  }
                };
              } else {
                this.insertInfo = {
                  store: data.store_id,
                  title: data.title,
                  content: data.content
                };
              }
            } else {
              this.router.navigate(["news/" + this.type]);
              this.err("네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.");
            }
          });
      }
    });

    this.form = this.fb.group({
      avatar: ["", Validators.required]
    });
  }

  setTmp(value) {
    let val = value.split(".");
    let text = "";
    let possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 30; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text + "." + val[1];
  }

  setFiles(e) {
    let readers: any = [];
    this.files = e.srcElement.files; //file을 담아준다.

    for (let i = 0; i < this.files.length; i++) {
      let reader = new FileReader();
      readers.push(reader);
      readers[i].readAsDataURL(e.target.files[i]);
      readers[i].onload = (event: any) => {
        this.fileList.push({
          id: i + 1,
          file: this.files[i],
          url: event.target.result
        });
      };
    }
  }

  // upload(){
  // 	this.filesForUpload = [];
  // 	this.tmpName = [];
  // 	let time = this.fileList.length * 300;
  // 	if (this.fileList.length>0) {
  // 		this.beforeUpload().then(()=>{
  // 			setTimeout(()=>{
  // 				this.uploadProvider.setNames(this.tmpName);
  // 				this.uploadProvider.addFilesToQueue(this.filesForUpload);
  // 				this.reset();
  // 			},time);
  // 		})
  // 	}
  // }

  // beforeUpload(){
  // 	return new Promise(resolve=>{
  // 		for(let i in this.fileList){
  // 			let name = this.setTmp(this.fileList[i].file.name);
  // 			this.tmpName.push(name);
  // 			this.filesForUpload.push(this.fileList[i].file);
  // 		}
  // 		resolve(true);
  // 	})
  // }

  reset() {
    this.nf.nativeElement.value = "";
    this.files = [];
    this.fileList = [];
    this.filesForUpload = [];
  }

  remove(i) {
    this.fileList.splice(i, 1);
  }

  // insertFileName(data){
  //	 return new Promise(resolve=>{
  //		 this.http.post('http://211.110.1.33:1234/insert_files', data)
  //		 .map(res=>res.json())
  //		 .subscribe(res=>{
  //			 resolve(res);
  //		 })
  //	 })
  // }

  switchEffect(e) {
    this.myDatePickerOptions = {
      dateFormat: "yyyy-mm-dd",
      dayLabels: {
        su: "일",
        mo: "월",
        tu: "화",
        we: "수",
        th: "목",
        fr: "금",
        sa: "토"
      },
      monthLabels: {
        1: "1월",
        2: "2월",
        3: "3월",
        4: "4월",
        5: "5월",
        6: "6월",
        7: "7월",
        8: "8월",
        9: "9월",
        10: "10월",
        11: "11월",
        12: "12월"
      },
      todayBtnTxt: "오늘날짜",
      componentDisabled: e,
      editableDateField: false
    };
  }

  register() {
    if (
      this.insertInfo.title === "" ||
      this.insertInfo.content === "" ||
      this.insertInfo.store === 999 ||
      (this.insertInfo.date === null && !this.check)
    ) {
      this.err("입력되지 않은 항목이 있습니다");
    } else {
      let data = {
        store: this.insertInfo.store,
        title: this.insertInfo.title,
        content: this.insertInfo.content,
        date: this.check ? "2099-12-31" : this.insertInfo.date.formatted
      };

      this.http.post("insert_employ", data).then(res => {
        if (res['result']) {
          const insertId = res['id'];
          this.err("등록되었습니다. 게시글로 이동합니다");
          this.router.navigate([`news/detail/employ/${insertId}`]);
        } else {
          this.err("네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.");
        }
      });
    }
  }

  // onSubmit() {
	//  const formData = new FormData();
  //   formData.append('avatar', this.file);

  //   // 폼데이터를 서버로 전송한다.
  //   this.httpClient.post(`${this.apiUrl}upload`, formData).subscribe(res => {
  //     if (res['success']) {
  //       this.avatar.setValue(null);
  //       this.registerNotice();
  //     }
  //   });
  // }

  onSubmit() {
    if ( this.insertInfo.title === '' || this.insertInfo.content === '' || this.insertInfo.store === 999 ) {
      this.err('입력되지 않은 항목이 있습니다');
      return;
    }
    const formData = new FormData();
    formData.append('avatar', this.file);

    // 공지사항 데이터 추가
    formData.append('store', this.insertInfo.store);
    formData.append('title', this.insertInfo.title);
    formData.append('content', this.insertInfo.content);

    // 서버로 전송
    this.httpClient.post(`${this.apiUrl}upload_notice`, formData).subscribe(res => {
      if (res['result']) {
        this.resetFile();
        this.err('등록되었습니다. 게시글로 이동합니다');
        const insertId = res['id'];
        this.router.navigate([`news/detail/notice/${insertId}`]);
      } else {
        this.err('네트워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
      }
    });
}

  get avatar() {
    return this.form.get('avatar');
  }

  registerNotice() {
    if ( this.insertInfo.title === '' || this.insertInfo.content === '' || this.insertInfo.store === 999 ) {
      this.err('입력되지 않은 항목이 있습니다');
    } else {
      // this.upload()
      const data = {
        store: this.insertInfo.store,
        title: this.insertInfo.title,
        content: this.insertInfo.content,
        files: this.tmpName
      };

      this.http.post('insert_notice', data).then(res => {
        if (res['result']) {
          this.resetFile();
          this.err('등록되었습니다. 게시글로 이동합니다');
          const insertId = res['id'];
          this.router.navigate([`news/detail/notice/${insertId}`]);
        } else {
          this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
        }
      });
    }
  }

  err(msg) {
    alert(msg);
  }

  modify() {
    if (this.type === 'employ') {
      if (
        this.insertInfo.title === '' ||
        this.insertInfo.content === '' ||
        this.insertInfo.store === 999 ||
        (this.insertInfo.date === null && !this.check)
      ) {
        this.err('입력되지 않은 항목이 있습니다');
      } else {
        const data = {
          id: this.id,
          store: this.insertInfo.store,
          title: this.insertInfo.title,
          content: this.insertInfo.content,
          date: this.check ? '2099-12-31' : this.insertInfo.date.formatted
        };

        this.http.post('update_employ', data).then(res => {
          if (res['result']) {
            this.err('수정되었습니다. 게시글로 이동합니다');
            this.router.navigate([`news/detail/${this.type}/${this.id}`]);
          } else {
            this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
          }
        });
      }
    } else if (this.type === 'notice') {
      if (
        this.insertInfo.title === '' ||
        this.insertInfo.content === '' ||
        this.insertInfo.store === 999
      ) {
        this.err('입력되지 않은 항목이 있습니다');
      } else {
        const data = {
          id: this.id,
          store: this.insertInfo.store,
          title: this.insertInfo.title,
          content: this.insertInfo.content
        };

        this.http.post('update_notice', data).then(res => {
          if (res['result']) {
            this.err('수정되었습니다. 게시글로 이동합니다');
            this.router.navigate([`news/detail/${this.type}/${this.id}`]);
          } else {
            this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
          }
        });
      }
    }
  }

  onFileChange(files: FileList) {
    if (files && files.length > 0) {
      // For Preview
      const reader = new FileReader();
      const file = files[0];
      this.file = files[0];
      this.tmpName.push(this.file.name);

      /* 브라우저는 보안 문제로 인해 파일 경로의 참조를 허용하지 않는다.
			따라서 파일 경로를 img 태그에 바인딩할 수 없다.
			FileReader.readAsDataURL 메소드를 사용하여 이미지 파일을 읽어
			base64 인코딩된 스트링 데이터를 취득한 후, img 태그에 바인딩한다. */
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageSrc = reader.result;
      };

      /* reactive form에서 input[type="file"]을 지원하지 않는다.
			즉 파일 선택 시에 값이 폼컨트롤에 set되지 않는다
			https://github.com/angular/angular.io/issues/3466
			form validation을 위해 file.name을 폼컨트롤에 set한다. */
      this.avatar.setValue(file.name);
    }
  }

  resetFile() {
    this.imageSrc = null;
    this.fileInput.nativeElement.value = '';
  }
}
