import { Component } from '@angular/core';
import { Company } from "./company";
import { HttpProvider } from "../_providers";


@Component({
  selector: 'history',
  templateUrl: './history.html',
  styleUrls: ['./history.scss']
})

export class Historyes {
  type:boolean = true;
  historys:any;

  constructor(
    public company : Company,
    public http : HttpProvider
  ){
    this.company.setLocation('history');
    this.http.get('select_history').then(res=>{
      if(res['result']){
        this.historys = res['data'];
      }
    })
  }
}
