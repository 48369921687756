import { Component } from '@angular/core';
import { Company } from "./company";
import { Router } from '@angular/router';
import { HttpProvider } from '../_providers';
import { environment } from 'src/environments/environment.prod';

@Component({
	selector: 'condition',
	templateUrl: './condition.html',
	styleUrls : ['./condition.scss']
})

export class Condition {
	image_api:string = environment.fileUrl;
	storeName:string;
	filteredItems:any;
	store_list:any;
	store_names:any;
	selected : number = 0;

	constructor(
		public company : Company,
		public router : Router,
		public http : HttpProvider
	){
		this.company.setLocation('condition');
		this.http.get('store_list').then(res=>{
			if(res['result']){
        this.store_names = res['data'];
			// let data = {
			// 	id:999,
			// 	name:"휘트니스"
			// }
			// this.store_names.push(data);
			}
		})
		this.http.get('select_list').then(res=>{
			if(res['result']){
				this.store_list = res['data'];
				// let data = {
				// 	address : "부산광역시 북구 만덕2로 28 (만덕동)",
				// 	fax : "",
				// 	ground : "",
				// 	id : 999,
				// 	image : "health.jpg",
				// 	name : "위 토탈 휘트니스 센터",
				// 	openhours : "06:00~23:00",
				// 	parking : "40~50대 (3시간 무료주차)",
				// 	tel : "0513322000"
				// };
				// this.store_list.push(data);
				this.assignCopy();
			}
		})
	}
	search(){

	}


	selectStore(id){
		this.selected = id;
	}

	leaflet(id, name){
		this.router.navigate([`shopping/leaflet/${name}/${id}`]);
		window.scrollTo(0, 0);
	}

	assignCopy(){
		this.filteredItems = Object.assign([], this.store_list);
	}

	filterItem(value){
		if(!value) this.assignCopy(); //when nothing has typed
		this.filteredItems = Object.assign([], this.store_list).filter(
			item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
		)
	}

}
