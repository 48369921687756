import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { AppComponent } from "../../app/app.component";
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpProvider } from '../_providers';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'main',
  templateUrl: './main.html',
  styleUrls : [ './main.scss' ]
})

export class Main implements OnInit {
  image_api:string = environment.fileUrl;
  auto:boolean=true;
  active:number=1;
  length:number;
  slides:string;
  today:any;
  weekday:any;
  items:any;
  banner_list:any;
  interval:any;

  quickMenu:any = [
    {id:1, title:"인터넷 전단", content:"특가상품을 알아보세요!", img:"leaflet.png"},
    {id:2, title:"지점 현황", content:"우리동네 우리마트 찾기", img:"place.png"},
    {id:3, title:"공지사항", content:"지금 우리마트에서는?", img:"mic.png"},
    {id:4, title:"채용공고", content:"함께할 직원을 모십니다", img:"suit.png"}
  ];

  constructor(
    public app : AppComponent,
    public router : Router,
    public datePipe : DatePipe,
    public http : HttpProvider
  ){
    this.app.setPath('main');
    this.http.get('select_items').then(res=>{
      if(res['result']){
        this.items = res['data'];
      }
    })

    this.loadBanner().then(res=>{
      if(res['result']){
        this.banner_list = res['data'];
        this.length = res['data'].length;
      }
    })
    // this.loadItems().then(res=>{
    //   if(res['result']){
    //     this.items = res['data'];
    //   }
    // })
    let today = new Date();
    let weekday = this.datePipe.transform(today, 'EEE');
    this.today = this.datePipe.transform(today, 'MM.dd');
    switch(weekday){
      case "Sun" : this.weekday = "일"; break;
      case "Mon" : this.weekday = "월"; break;
      case "Tue" : this.weekday = "화"; break;
      case "Wed" : this.weekday = "수"; break;
      case "Thu" : this.weekday = "목"; break;
      case "Fri" : this.weekday = "금"; break;
      case "Sat" : this.weekday = "토"; break;
    }
  }

  loadBanner(){
    return new Promise(resolve=>{
      this.http.get('select_banner').then(res=>{
        resolve(res);
      })
    })
  }

  prev(){
    if(this.active == 1){
      this.active = this.length+1;
    }
    this.active--;
  }

  next(){
    if(this.active == this.length){
      this.active = 0;
    }
    this.active++;
  }

  onPrev(){
    this.prev();
    if(this.auto){
      clearInterval(this.interval);
      this.interval = setInterval(() => {
         this.next();
       }, 4000);
    }
  }

  onNext(){
    this.next();
    if(this.auto){
      clearInterval(this.interval);
      this.interval = setInterval(() => {
         this.next();
       }, 4000);
    }
  }

  autoPlayOff(){
    this.auto = false;
    clearInterval(this.interval);
  }

  autoPlayOn(){
    this.auto = true;
    this.interval = setInterval(() => {
       this.next();
     }, 4000);
  }

  ngOnInit() {
    this.autoPlayOn();
  }

  transform(type){
    let url = "";
    let param = {};
    switch(type){
      case 1 : {
        url = 'shopping/leaflet/감천점/2';
        // param = {id:2, name:'감천점'};
      } break;
      case 2 : {
        url = 'company/condition';
      } break;
      case 3 : {
        url = 'news/notice';
      } break;
      case 4 : {
        url = 'news/employ';
      } break;
    }
    this.router.navigate([url, param]);
    window.scrollTo(0,0);
  }

  bannerLink(link){
    if(link==1){
      this.router.navigate(['shopping/mobile']);
    }else{
      // this.router.navigate(['shopping/leaflet', {id:2,name:'감천점'}],{ skipLocationChange: true });
      this.router.navigate(['shopping/leaflet/감천점/2']);
    }
    window.scrollTo(0,0);
  }
}
