import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment.prod";

@Injectable()
export class HttpProvider {
  DB_API: string = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getExternal(url: string) {
    return new Promise(resolve => {
      this.http.get(url).subscribe(res => {
        resolve(res);
      });
    });
  }

  get(SQL: string) {
    return new Promise(resolve => {
      this.http.get(this.DB_API + SQL).subscribe(res => {
        resolve(res);
      });
    });
  }

  post(SQL: string, arr: any) {
    return new Promise(resolve => {
      this.http.post(this.DB_API + SQL, arr, { withCredentials: true }).subscribe(res => {
        resolve(res);
      });
    });
  }

  formDataPost(SQL: string, arr: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return new Promise(resolve => {
      this.http.post(this.DB_API + SQL, arr, { headers, withCredentials: true }).subscribe(res => {
        resolve(res);
      })
    })
  }
}
