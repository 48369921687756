import { Component } from '@angular/core';
import { AppComponent } from "../app.component";

@Component({
  selector: 'company',
  templateUrl: './company.html'
})

export class Company {
  path : string;
  constructor(
    public app : AppComponent
  ){
    this.app.setPath('company');
  }

  setLocation(menu){
    this.path = menu;
    window.scrollTo(0,0);
  }
}
