import { Component, ElementRef, ViewChild } from "@angular/core";
import { Customer } from "./customer";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { HttpProvider, IpServiceService } from "../_providers";

@Component({
  selector: "guest-write",
  templateUrl: "./guest-write.html",
  styleUrls: ["./guest-write.scss"],
})
export class GuestWrite {
  @ViewChild("fileInput")
  fileInput: ElementRef;
  files: any = [];
  agree: boolean = false;
  store_list: any;
  type: any;
  modi_id: number;
  email: string = "";
  emails: string = "s";
  insertInfo: any = {
    name: "",
    email: "",
    emailFull: "",
    pwd: "",
    store: 999,
    phone: "",
    title: "",
    content: "",
  };
  constructor(
    public customer: Customer,
    public router: Router,
    public http: HttpProvider,
    public route: ActivatedRoute,
    private ipService: IpServiceService
  ) {
    window.scrollTo(0, 0);
    this.customer.setLocation("guest");
    this.http.get("store_list").then((res) => {
      if (res["result"]) {
        this.store_list = res["data"];
      }
    });

    this.route.params.subscribe((params) => {
      this.type = params["type"]; // write // modify
      this.modi_id = params["id"];

      switch (this.type) {
        case "modify":
          {
            this.http.post("select_post", { id: this.modi_id }).then((res) => {
              if (res["result"]) {
                let data = res["data"];
                this.insertInfo.name = data.writer;
                this.insertInfo.title = data.title;
                this.insertInfo.content = data.content;
                this.insertInfo.store = data.store_id;
                if (data.email != "") {
                  this.emails = "none";
                  let email = data.email.split("@");
                  this.insertInfo.email = email[0];
                  this.email = email[1];
                }
              }
            });
          }
          break;

        case "reply": {
          this.http.post("select_post", { id: this.modi_id }).then((res) => {
            if (res["result"]) {
              let data = res["data"];
              let head = "<" + data.writer + ">님이 작성한 글입니다.\r\n\r\n";
              let line =
                "\r\n\r\n-----------------------------------------------------------------------------------------------------\r\n\r\n";
              this.insertInfo.name = "관리자";
              this.insertInfo.title = "RE : " + data.title;
              this.insertInfo.content = head + data.content + line;
              this.insertInfo.store = 0;
            }
          });
        }
      }
    });
  }

  reset() {
    this.files = [];
    this.fileInput.nativeElement.value = "";
  }

  remove(i) {
    this.files = this.files.filter((item, idx) => idx !== i);
  }

  onChangeFiles(ev) {
    const {
      target: { files },
    } = ev;
    const filesArray = Array.from(files);
    this.files = [...this.files, ...filesArray];
    this.fileInput.nativeElement.value = "";
  }
  test() {
    console.log("dd");
    console.log(this.agree);
  }

  err(msg) {
    alert(msg);
  }

  success(msg) {
    alert(msg);
  }

  async register() {
    if (!this.agree) {
      this.err(
        "의견 작성시 개인정보 수집 및 이용안내에 대한 동의가 필요합니다"
      );
    } else if (
      this.insertInfo.name == "" ||
      this.insertInfo.pwd == "" ||
      this.insertInfo.store == 999 ||
      this.insertInfo.title == "" ||
      this.insertInfo.content == ""
    ) {
      this.err("입력되지 않은 항목이 있습니다");
    } else if (this.insertInfo.pwd.length < 4) {
      this.err("비밀번호는 최소 4자 이상 입력해주세요");
    } else {
      if (
        (this.emails == "s" && this.insertInfo.email != "") ||
        (this.emails == "none" && this.email == "")
      ) {
        this.err("이메일 주소가 정확하지 않습니다");
      } else {
        if (this.insertInfo.email == "") {
          //없는경우
          this.insertInfo.emailFull = "";
        } else if (this.emails == "none") {
          //있는데 직접입력
          this.insertInfo.emailFull = this.insertInfo.email + "@" + this.email;
        } else {
          //있는데 선택
          this.insertInfo.emailFull = this.insertInfo.email + "@" + this.emails;
        }

        const formData = new FormData();

        for (let key in this.insertInfo) {
          formData.append(key, this.insertInfo[key]);
        }

        if (this.files.length > 0) {
          for (let key in this.files) {
            formData.append("guest", this.files[key]);
          }
        }

        // const response = await firstValueFrom(this.ipService.getIPAddress());
        // const userIP = response.data.IPv4 || "";
        // formData.append('ip', userIP);

        // const response = await axios.get("https://geolocation-db.com/json/");
        // console.log(response.data);

        try {
          const response: any = await this.http.getExternal(
            "https://ipinfo.io/json" // 새로운 IP API URL
          );

          // IP 값을 확인하고, 없으면 빈 문자열로 설정
          const userIP = response && response.ip ? response.ip : "";

          // IP 값을 formData에 추가
          formData.append("ip", userIP);

          // 등록 요청 전송
          this.http.post("register_post", formData).then((res) => {
            if (res["result"]) {
              this.success("등록되었습니다.");
              // this.router.navigate(['customer/guest-detail', {id:res['id']}]);
              this.insertInfo = {
                name: "",
                email: "",
                emailFull: "",
                pwd: "",
                store: 999,
                phone: "",
                title: "",
                content: "",
              };
              this.reset();
            } else {
              this.err("네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.");
            }
          });
        } catch (error) {
          // IP 요청 중 오류 발생 시 처리
          console.error("IP 요청 중 오류 발생:", error);
          formData.append("ip", ""); // 빈 값으로 설정

          // 등록 요청 전송
          this.http.post("register_post", formData).then((res) => {
            if (res["result"]) {
              this.success("등록되었습니다.");
              // this.router.navigate(['customer/guest-detail', {id:res['id']}]);
              this.insertInfo = {
                name: "",
                email: "",
                emailFull: "",
                pwd: "",
                store: 999,
                phone: "",
                title: "",
                content: "",
              };
              this.reset();
            } else {
              this.err("네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.");
            }
          });
        }
      }
    }
  }

  reply() {
    if (this.insertInfo.title == "" || this.insertInfo.content == "") {
      this.err("입력되지 않은 항목이 있습니다");
    } else {
      this.http
        .post("reply", {
          id: this.modi_id,
          title: this.insertInfo.title,
          content: this.insertInfo.content,
        })
        .then((res) => {
          if (res["result"]) {
            this.success("등록되었습니다. 게시글로 이동합니다");
            this.router.navigate(["customer/guest-detail", { id: res["id"] }]);
          } else {
            this.err("네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.");
          }
        });
    }
  }

  modify() {
    if (
      this.insertInfo.name == "" ||
      this.insertInfo.pwd == "" ||
      this.insertInfo.store == 999 ||
      this.insertInfo.title == "" ||
      this.insertInfo.content == ""
    ) {
      this.err("입력되지 않은 항목이 있습니다");
    } else if (this.insertInfo.pwd.length < 4) {
      this.err("비밀번호는 최소 4자 이상 입력해주세요");
    } else {
      if (
        (this.emails == "s" && this.insertInfo.email != "") ||
        (this.emails == "none" && this.email == "")
      ) {
        this.err("이메일 주소가 정확하지 않습니다");
      } else {
        if (this.insertInfo.email == "") {
          //없는경우
          this.insertInfo.emailFull = "";
        } else if (this.emails == "none") {
          //있는데 직접입력
          this.insertInfo.emailFull = this.insertInfo.email + "@" + this.email;
        } else {
          //있는데 선택
          this.insertInfo.emailFull = this.insertInfo.email + "@" + this.emails;
        }

        this.http
          .post("modify_post", { data: this.insertInfo, id: this.modi_id })
          .then((res) => {
            if (res["result"]) {
              this.success("수정되었습니다. 게시글로 이동합니다.");
              this.router.navigate([
                "customer/guest-detail",
                { id: this.modi_id },
              ]);
            } else {
              this.err("네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.");
            }
          });
      }
    }
  }

  rejectKeyCode(e) {
    //스페이스 바 + 특수문자
    if (
      e.keyCode == 32 ||
      e.keyCode == 92 ||
      e.keyCode == 124 ||
      e.keyCode == 33 ||
      e.keyCode == 96 ||
      e.keyCode == 126 ||
      e.keyCode == 64 ||
      e.keyCode == 35 ||
      e.keyCode == 36 ||
      e.keyCode == 37 ||
      e.keyCode == 94 ||
      e.keyCode == 38 ||
      e.keyCode == 42 ||
      e.keyCode == 40 ||
      e.keyCode == 41 ||
      e.keyCode == 45 ||
      e.keyCode == 95 ||
      e.keyCode == 61 ||
      e.keyCode == 43 ||
      e.keyCode == 43 ||
      e.keyCode == 61 ||
      e.keyCode == 123 ||
      e.keyCode == 125 ||
      e.keyCode == 91 ||
      e.keyCode == 93 ||
      e.keyCode == 59 ||
      e.keyCode == 58 ||
      e.keyCode == 39 ||
      e.keyCode == 34 ||
      e.keyCode == 44 ||
      e.keyCode == 46 ||
      e.keyCode == 60 ||
      e.keyCode == 62 ||
      e.keyCode == 47 ||
      e.keyCode == 63
    ) {
      return false;
    }
  }

  rejectKeyCode2(e) {
    //스페이스 바 + 특수문자
    if (
      e.keyCode == 32 ||
      e.keyCode == 92 ||
      e.keyCode == 124 ||
      e.keyCode == 33 ||
      e.keyCode == 96 ||
      e.keyCode == 126 ||
      e.keyCode == 64 ||
      e.keyCode == 35 ||
      e.keyCode == 36 ||
      e.keyCode == 37 ||
      e.keyCode == 94 ||
      e.keyCode == 38 ||
      e.keyCode == 42 ||
      e.keyCode == 40 ||
      e.keyCode == 41 ||
      e.keyCode == 45 ||
      e.keyCode == 95 ||
      e.keyCode == 61 ||
      e.keyCode == 43 ||
      e.keyCode == 43 ||
      e.keyCode == 61 ||
      e.keyCode == 123 ||
      e.keyCode == 125 ||
      e.keyCode == 91 ||
      e.keyCode == 93 ||
      e.keyCode == 59 ||
      e.keyCode == 58 ||
      e.keyCode == 39 ||
      e.keyCode == 34 ||
      e.keyCode == 44 ||
      e.keyCode == 60 ||
      e.keyCode == 62 ||
      e.keyCode == 47 ||
      e.keyCode == 63
    ) {
      return false;
    }
  }

  // loadPostForModify(id){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/select_post', {id:id})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  // loadStores(){
  //   return new Promise(resolve=>{
  //     this.http.get('http://211.110.1.33:1234/store_list')
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  // replyPost(){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/reply', {id:this.modi_id, title:this.insertInfo.title, content:this.insertInfo.content})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  // registerPost(){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/register_post', {data:this.insertInfo})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  // modifyPost(){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/modify_post', {data:this.insertInfo, id:this.modi_id})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }
}
