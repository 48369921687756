import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Main, Pwd } from './';
import { Shopping, Company, Customer, News } from "./";
import { Bonus, Gift, Leaflet, Mobile } from "./";
import { Ceo, Condition, Historyes, Info } from "./";
import { Faq, Enter, Guest, GuestDetail, GuestWrite, GuestPwd, View, EnterDetail } from "./";
import { Employ, Notice, Detail, EmployWrite } from "./";

export const appRoutes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'main', component: Main},
  { path: 'shopping', component: Shopping,
    children : [
      { path: 'gift', component: Gift },
      { path: 'leaflet/:name/:store', component: Leaflet },
      { path: 'mobile', component: Mobile },
      { path: 'bonus', component: Bonus  }
    ]
  },
  { path: 'company', component: Company,
    children : [
      { path: 'ceo', component: Ceo },
      { path: 'condition', component: Condition },
      { path: 'history', component: Historyes },
      { path: 'info', component: Info }
    ]
  },
  { path: 'customer', component: Customer,
    children : [
      { path: 'faq', component: Faq },
      { path: 'enter', component: Enter },
      { path: 'enter-view', component: View },
      { path: 'enter-detail', component: EnterDetail },
      { path: 'guest-view', component: Guest },
      { path: 'guest', component: GuestWrite },
      { path: 'guest-detail', component: GuestDetail },
      { path: 'guest-pwd', component: GuestPwd }
    ]
  },
  { path: 'news', component: News,
    children : [
      { path: 'employ', component: Employ },
      { path: 'detail/:type/:id', component: Detail },
      { path: 'pwd', component: Pwd },
      { path: 'employ-write', component: EmployWrite },
      { path: 'notice', component: Notice }
    ]
  },

  // otherwise redirect to home
  { path: '**', redirectTo: 'main' }
];

export const routing : ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);
