import { Component } from '@angular/core';
import { Company } from "./company";

@Component({
  selector: 'ceo',
  templateUrl: './ceo.html'
})

export class Ceo {
  constructor(
    public company : Company
  ){
    this.company.setLocation('ceo');
  }
}
