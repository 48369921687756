import { Component } from '@angular/core';
import { Customer } from "./customer";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpProvider } from "../_providers";


@Component({
  selector: 'guest-detail',
  templateUrl: './guest-detail.html',
  styleUrls: ['./guest-detail.scss']
})

export class GuestDetail {
  attaches = [];
  id : number;
  num : number;
  prev : any = {
    id : 0,
    title : "",
    groups : 0
  };
  next : any = {
    id : 0,
    title : "",
    groups : 0
  };
  detailInfo : any = {
    admin : 0,
    content : "",
    date : "",
    groups : 0,
    id : 0,
    level : 0,
    name : "",
    num : 0,
    segment : 0,
    store_id : 0,
    title : "",
    writer : "",
    phone : "",
    email : ""
  };

  constructor(
    public customer : Customer,
    public http : HttpProvider,
    public router : Router,
    public route : ActivatedRoute
  ){
    window.scrollTo(0,0);
    this.customer.setLocation('guest');
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getAttaches(params['id']);
      this.http.post('select_detail', {id:this.id}).then(res=>{
        if(res['result']){
          let data = res['data']; //이전글, 현재글, 다음글 순서로 온다
          let num = res['num']; //이전글, 현재글, 다음글 순서로 온다
          if(data.length==3){
            this.detailInfo = data[1];
            this.prev = {
              id:data[0].id,
              title:data[0].title,
              groups:data[0].groups
            };
            this.next = {
              id:data[2].id,
              title:data[2].title,
              groups:data[2].groups
            };
          }else if(data.length==2){
            if(num == 1){ // 첫글을 누른 상황
              this.detailInfo = data[0];
              this.next = {
                id:data[1].id,
                title:data[1].title,
                groups:data[1].groups
              };
              this.prev = {
                id : 0,
                title : ""
              };
            }else{ // 마지막 글을 누른 상황
              this.detailInfo = data[1];
              this.prev = {
                id:data[0].id,
                title:data[0].title,
                groups:data[0].groups
              };
              this.next = {
                id : 0,
                title : ""
              };
            }
          }else{ //글이 하나인 상황
            this.detailInfo = data[0];
          }
        }
      })
    });
  }

  getAttaches(enter_id: number) {
    this.http.get(`getAttaches/${enter_id}/guest`).then(res => {
      if(res['result']){
        this.attaches = res['data'];
      }
    })
  }
  

  // loadDetail(id){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/select_detail', {id:id})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  theOther(id, groups){
    if(id){
      // if(groups == this.detailInfo.groups){
        this.router.navigate(['customer/guest-detail', {id:id}]);
      // }else{
      //   this.router.navigate(['customer/guest-pwd', {id:id, type:'read'}]);
      // }
    }
  }

  toList(){
    this.router.navigate(['customer/guest-view']);
  }

  pwd(type){
    this.router.navigate(['customer/guest-pwd', {type:type, id:this.id}]);
  }
}
