import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
	path : string;
	word : string = "";

	constructor(public router:Router){}

	search(){
	  this.word = "";
	}

	setPath(path){
	  this.path = path;
	  window.scrollTo(0,0);
	}

	leaf(){
	  this.router.navigate(['shopping/leaflet/감천점/2']);
	  window.scrollTo(0,0);
	}

	mobile(){
	  this.router.navigate(['shopping/mobile']);
	}

	facebook(){
	  window.open('https://www.facebook.com/woorimart.kr');
	}

	blog(){
	  alert('준비중입니다');
	}
 }
