import { Component } from '@angular/core';
import { Customer } from "./customer";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpProvider } from "../_providers";


@Component({
  selector: 'enter-detail',
  templateUrl: './detail.html',
  styleUrls: ['./detail.scss']
})

export class EnterDetail {
  attaches = [];
  detailInfo:any = {
    name: '',
    phone: '',
    content: '',
  };

  constructor(
    public customer : Customer,
    public http : HttpProvider,
    public router : Router,
    public route : ActivatedRoute
  ){
    window.scrollTo(0,0);
    this.customer.setLocation('guest');
    this.route.params.subscribe(params => {
      this.http.get('getEnterDetail?enter_id=' + params['id']).then(res=>{
        if(res['result']){
          this.detailInfo = res['data'][0];
        }
      });

      this.getAttaches(params['id']);
    });
  }
  
  getAttaches(enter_id: number) {
    this.http.get(`getAttaches/${enter_id}/enter`).then(res => {
      if(res['result']){
        this.attaches = res['data'];
      }
    })
  }

  // loadDetail(id){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/select_detail', {id:id})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  toList(){
    this.router.navigate(['customer/enter-view']);
  }

}
