import { Component } from '@angular/core';
import { Customer } from "./customer";
import { HttpProvider } from "../_providers";


@Component({
  selector: 'faq',
  templateUrl: './faq.html',
  styleUrls: ['./faq.scss']
})

export class Faq {
  notice_list:any;
  type : number = 0;
  view : number = 0;

  constructor(
    public customer : Customer,
    public http : HttpProvider
  ){
    window.scrollTo(0,0);
    this.customer.setLocation('faq');
    this.http.get('select_faq').then(res=>{
      if(res['result']){
        this.notice_list = res['data'];
      }else{
        alert('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
      }
    })
  }

  // loadNotice(){
  //   return new Promise(resolve=>{
  //     this.http.get('http://211.110.1.33:1234/select_faq')
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  onView(id){
    if(this.view == id){
      this.view = 0;
    }else{
      this.view = id;
    }

    // if(this.view==0){
    //   this.view = id;
    // }else{
    //   this.view = 0;
    // }
  }
}
