import { Component } from '@angular/core';
import { Shopping } from "./shopping";

@Component({
  selector: 'gift',
  templateUrl: './gift.html'
})

export class Gift {
  constructor(
    public shopping : Shopping
  ){
    this.shopping.setLocation('gift');
  }
}
