import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'size'
})
export class SizePipe implements PipeTransform{
  transform(size:number) : string{
    let fSExt = new Array('Bytes', 'KB', 'MB', 'GB'), i = 0;
    while(size>900){
      size/=1024;
      i++;
    }
    let exactSize = (Math.round(size*100)/100)+' '+fSExt[i];
    return exactSize;
  }
}
