import { Component } from '@angular/core';
import { News } from "./news";
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpProvider } from '../_providers';

@Component({
  selector: 'pwd',
  templateUrl: './pwd.html'
})

export class Pwd {
  pwd : any;
  id : number;
  group : number;
  type : string;
  use : string;

  constructor(
    public news : News,
    public http : HttpProvider,
    public router : Router,
    public route : ActivatedRoute
  ){
    window.scrollTo(0,0);
    this.news.setLocation('employ');
    this.route.params.subscribe(params => {
      this.type  = params['type'];
      this.use  = params['use'];
      if(params['id']){
        this.id  = params['id'];
      }
    });
  }

  err(msg){
    alert(msg);
  }

  toList(){
    let url = "news/"+this.type
    this.router.navigate([url]);
  }

  submit(){
    if(this.type == 'employ'){
      switch(this.use){
        case 'write' : { this.employWrite(); }break;
        case 'delete' : { this.employDelete(); }break;
        case 'modify' : { this.employModify(); }break;
      }

    }else if(this.type == 'notice'){
      switch(this.use){
        case 'write' : { this.noticeWrite(); }break;
        case 'delete' : { this.noticeDelete(); }break;
        case 'modify' : { this.noticeModify(); }break;
      }
    }
  }

  employModify(){
    this.http.post('reply_post', {pwd:this.pwd}).then(res=>{
      if(res['result']){
        if(res['result2']){
          this.router.navigate(['news/employ-write', {type:'employ', use:'modify', id:this.id}]);
          window.scrollTo(0,0);
        }else{
          this.pwd = "";
          this.err('비밀번호가 일치하지 않습니다');
        }
      }else{
        this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
      }
    })
    // this.checkPwd().then(res=>{
    //   if(res['result']){
    //     if(res['result2']){
    //       this.router.navigate(['news/employ-write', {type:'employ', use:'modify', id:this.id}]);
    //       window.scrollTo(0,0);
    //     }else{
    //       this.pwd = "";
    //       this.err('비밀번호가 일치하지 않습니다');
    //     }
    //   }else{
    //     this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
    //   }
    // })
  }

  employWrite(){
    this.http.post('reply_post', {pwd:this.pwd}).then(res=>{
      if(res['result']){
        if(res['result2']){
          this.router.navigate(['news/employ-write', {type:'employ', use:'write'}]);
          window.scrollTo(0,0);
        }else{
          this.pwd = "";
          this.err('비밀번호가 일치하지 않습니다');
        }
      }else{
        this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
      }
    })
  }

  employDelete(){
    this.http.post('reply_post', {pwd:this.pwd}).then(res=>{
      if(res['result']){
        if(res['result2']){
          this.http.post('delete_employ', {id:this.id}).then(res=>{
            if(res['result']){
              this.err('삭제되었습니다');
              this.toList();
            }else{
              this.err('네크워크에 오류가 있습니다. 잠시 후 다시 시도해주세요.');
            }
          })
        }else{
          this.pwd = "";
          this.err('비밀번호가 일치하지 않습니다');
        }
      }else{
        this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
      }
    })
  }

  noticeModify(){
    this.http.post('reply_post', {pwd:this.pwd}).then(res=>{
      if(res['result']){
        if(res['result2']){
          this.router.navigate(['news/employ-write', {type:'notice', use:'modify', id:this.id}]);
          window.scrollTo(0,0);
        }else{
          this.pwd = "";
          this.err('비밀번호가 일치하지 않습니다');
        }
      }else{
        this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
      }
    })
  }

  noticeWrite(){
    this.http.post('reply_post', {pwd:this.pwd}).then(res=>{
      if(res['result']){
        if(res['result2']){
          this.router.navigate(['news/employ-write', {type:'notice', use:'write'}]);
          window.scrollTo(0,0);
        }else{
          this.pwd = "";
          this.err('비밀번호가 일치하지 않습니다');
        }
      }else{
        this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
      }
    })
  }

  noticeDelete(){
    this.http.post('reply_post', {pwd:this.pwd}).then(res=>{
      if(res['result']){
        if(res['result2']){
          this.http.post('delete_notice', {id:this.id}).then(res=>{
            if(res['result']){
              this.err('삭제되었습니다');
              this.toList();
            }else{
              this.err('네크워크에 오류가 있습니다. 잠시 후 다시 시도해주세요.');
            }
          })
        }else{
          this.pwd = "";
          this.err('비밀번호가 일치하지 않습니다');
        }
      }else{
        this.err('네크워크가 불안정합니다. 잠시 후에 다시 시도해주세요.');
      }
    })
  }

  // checkPwd(){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/reply_post', {pwd:this.pwd})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  // deletePost(){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/delete_employ', {id:this.id})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }

  // deletePost2(){
  //   return new Promise(resolve=>{
  //     this.http.post('http://211.110.1.33:1234/delete_notice', {id:this.id})
  //     .map(res=>res.json())
  //     .subscribe(res=>{
  //       resolve(res);
  //     })
  //   })
  // }
}
